import { FC, useCallback, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  CloseModalButton,
  BackModalButton,
  MainButton,
} from "components/Buttons";
import BasicModal from "./BasicModal";
import { TwoFaModal } from "components/Modals";
import { TextInput } from "components/Inputs";
import {
  ModalHeading,
  ModalContent,
  InfoDecorative,
  InputContainer,
} from "./styled";
import {
  CardImageContainer,
  CardBack,
  CardBackLine,
} from "pages/CryptoCardsPage/styled";
import {
  colors,
  virtualCardDesigns,
  extraVirtualCardDesigns,
} from "helpers/consts";
import { useMediaQuery } from "helpers/hooks";
import { format } from "helpers/funcs";
import { AppText, Preloader } from "components";
import { add2FAVerification } from "api/user";
import { getCreditcoCardWithCredentials } from "api/creditco";
import { GlobalStore, UserStore } from "stores";
import type { CredictcoCardDetails, CreditcoCard } from "helpers/types";

import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";
interface CardDetailsModalProps {
  cardInfo?: CreditcoCard | null;
  isOpen: boolean;
  onClose: () => void;
}
const CardDetailsModal: FC<CardDetailsModalProps> = ({
  cardInfo,
  onClose,
  isOpen,
}) => {
  const laptop = useMediaQuery("(min-width: 30em)");
  const mobile = useMediaQuery("(min-width: 26em)");
  const { t } = useTranslation();

  const {
    user: { is2FAEnabled },
  } = UserStore;

  const [isTwoFaOpen, setIsTwoFaOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [twoFaUrl, setTwoFaUrl] = useState<string>("");
  const [twoFACode, setTwoFACode] = useState<string>("");
  const [fullCardInfo, setFullCardInfo] = useState<CredictcoCardDetails | null>(
    null
  );

  const change2Fa = useCallback(() => {
    if (is2FAEnabled) {
      setIsTwoFaOpen(true);
      return;
    }
    setIsLoading(true);
    add2FAVerification()
      .then(({ data }) => {
        setTwoFaUrl(data.url);
        setIsTwoFaOpen(true);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  }, [is2FAEnabled, setIsLoading]);

  const getCardDetails = useCallback(async () => {
    if (!cardInfo || fullCardInfo) {
      return;
    }
    setIsLoading(true);

    getCreditcoCardWithCredentials(cardInfo.id, twoFACode)
      .then(({ data }) => {
        setTwoFACode("");
        setFullCardInfo(data);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
    setIsLoading(false);
  }, [twoFACode, cardInfo, fullCardInfo]);

  useEffect(() => {
    if (twoFACode.length === 6) {
      getCardDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFACode]);

  const resetState = useCallback(() => {
    setIsTwoFaOpen(false);
    setTwoFaUrl("");
    setTwoFACode("");
    setFullCardInfo(null);
  }, []);

  return (
    <BasicModal onExited={resetState} isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <BackModalButton onBack={onClose} label="back" />
        <CloseModalButton onClose={onClose} label="close" />
        {isLoading && <Preloader isStatic />}
        <ModalHeading $centered>{t("CARD_DETAILS")}</ModalHeading>

        {!is2FAEnabled ? (
          <>
            <AppText fontSize={18} fontWeight={600}>
              {t("TWO_FA_FOR_DETAILS")}
            </AppText>

            <MainButton
              maxWidth="14rem"
              style={{ marginTop: "1.6rem" }}
              variant="contained"
              onClick={() => change2Fa()}
            >
              <LockIcon />
              {t("ENABLE")}
            </MainButton>
          </>
        ) : (
          <>
            {!fullCardInfo ? (
              <InputContainer $marginTop>
                <AppText>{t("TWO_FA_FOR_CARD_DETAILS")}</AppText>
                <TextInput
                  placeholder={t("CODE")}
                  value={twoFACode}
                  onChange={({ target: { value } }) => setTwoFACode(value)}
                  leftIcon={<LockIcon />}
                  autoComplete="off"
                  name="2fa-code"
                />
              </InputContainer>
            ) : (
              <InfoDecorative $isAlternative>
                <CardImageContainer>
                  <CardBack
                    $bgColor={
                      fullCardInfo
                        ? fullCardInfo.designCode === "CA02"
                          ? extraVirtualCardDesigns[fullCardInfo.currencyName]
                              ?.bgColor || undefined
                          : virtualCardDesigns[fullCardInfo.designCode]
                              ?.bgColor || undefined
                        : undefined
                    }
                  >
                    <CardBackLine
                      $lineColor={
                        fullCardInfo
                          ? fullCardInfo.designCode === "CA02"
                            ? extraVirtualCardDesigns[fullCardInfo.currencyName]
                                ?.lineColor || undefined
                            : virtualCardDesigns[fullCardInfo.designCode]
                                ?.lineColor || undefined
                          : undefined
                      }
                    />
                  </CardBack>

                  {mobile && fullCardInfo?.embossName && (
                    <>
                      <AppText
                        fontSize={laptop ? 14 : 12}
                        fontWeight={400}
                        color={colors.white}
                        style={{
                          bottom: laptop ? "17.2rem" : "13.6rem",
                          textTransform: "uppercase",
                        }}
                      >
                        {t("CARD_HOLDER")}
                      </AppText>
                      <AppText
                        fontSize={laptop ? 24 : 16}
                        fontWeight={400}
                        color={colors.white}
                        style={{
                          bottom: laptop ? "14rem" : "11.6rem",
                        }}
                      >
                        {fullCardInfo.embossName}
                      </AppText>
                    </>
                  )}

                  <AppText
                    fontSize={laptop ? 14 : 12}
                    fontWeight={400}
                    color={colors.white}
                    style={{
                      bottom: laptop ? "11.5rem" : "8.7rem",
                    }}
                  >
                    {t("CARD_NUMBER")}
                  </AppText>
                  <AppText
                    fontSize={laptop ? 32 : 18}
                    fontWeight={400}
                    color={colors.white}
                    style={{
                      bottom: laptop ? "7.5rem" : "6.4rem",
                    }}
                  >
                    {format(
                      !fullCardInfo.cardNumber ||
                        fullCardInfo.cardNumber === "0000000000000000000"
                        ? fullCardInfo.maskedCardNumber
                        : fullCardInfo.cardNumber,
                      "9999 9999 9999 9999"
                    )}
                  </AppText>

                  {fullCardInfo?.expiryDate && (
                    <>
                      <AppText
                        fontSize={laptop ? 14 : 12}
                        fontWeight={400}
                        color={colors.white}
                        style={{
                          bottom: laptop ? "5rem" : "3.8rem",
                        }}
                      >
                        {t("EXPIRY_DATE")}
                      </AppText>
                      <AppText
                        fontSize={laptop ? 34 : 18}
                        fontWeight={400}
                        color={colors.white}
                        style={{
                          bottom: laptop ? "1rem" : "1.6rem",
                        }}
                      >
                        {moment(fullCardInfo.expiryDate).format("M/YY")}
                      </AppText>
                    </>
                  )}

                  <AppText
                    fontSize={laptop ? 14 : 12}
                    fontWeight={400}
                    color={colors.white}
                    style={{
                      bottom: laptop ? "5rem" : "3.8rem",
                      left: "auto",
                      right: "1.6rem",
                    }}
                  >
                    {t("SECURITY_CODE")}
                  </AppText>
                  <AppText
                    fontSize={laptop ? 34 : 18}
                    fontWeight={400}
                    color={colors.white}
                    style={{
                      bottom: laptop ? "1rem" : "1.6rem",
                      left: "auto",
                      right: "1.6rem",
                    }}
                  >
                    {fullCardInfo.cvv || "***"}
                  </AppText>
                </CardImageContainer>
                {!mobile && fullCardInfo?.embossName && (
                  <AppText
                    color={colors.gray_900}
                    style={{
                      margin: "1rem 0 0 0.4rem",
                      alignSelf: "flex-start",
                    }}
                  >
                    {t("CARD_HOLDER")}: <b>{fullCardInfo.embossName}</b>
                  </AppText>
                )}
              </InfoDecorative>
            )}
          </>
        )}
      </ModalContent>

      <TwoFaModal
        isOpen={isTwoFaOpen}
        url={twoFaUrl}
        onClose={() => setIsTwoFaOpen(false)}
      />
    </BasicModal>
  );
};

export default observer(CardDetailsModal);

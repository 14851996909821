import { FC } from "react";
import styled from "styled-components";
import {
  OutlinedInput,
  Button,
  InputAdornment,
  ButtonProps,
} from "@mui/material";
import { InfoBlock } from "./ReadInfo";
import { TextInputProps } from "./types";
import { colors, currencyIcons } from "helpers/consts";
import { manualNumInputValidator } from "helpers/regex";
import { fiatIcons } from "helpers/fiatOptions";
import { AppText } from "components";
import type { CurrencyInterface } from "helpers/types";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down-mini.svg";
import { ReactComponent as NAIcon } from "assets/icons/coins/n_a.svg";

interface CurrencyInputProps extends TextInputProps {
  currency?: string | null;
  currencies?: CurrencyInterface[] | null;
  onClick?: () => void;
  noSelect?: boolean;
  isThin?: boolean;
  isLoading?: boolean;
}

const CurrencyInput: FC<CurrencyInputProps> = ({
  value,
  setValue,
  disabled,
  currency,
  currencies,
  onClick,
  noSelect = false,
  isThin = false,
  placeholder,
  isLoading,
}) => {
  const handleChange = (value: string) => {
    if (!setValue) {
      return null;
    }
    const precision =
      (currency &&
        currencies?.find(({ name }) => name === currency)?.precision) ||
      8;

    const fixedSeparatorsValue = value.replace(",", ".");

    const canSetNewValue =
      manualNumInputValidator(precision).test(fixedSeparatorsValue) ||
      !fixedSeparatorsValue.length;

    if (canSetNewValue && setValue) {
      setValue(fixedSeparatorsValue);
    }
  };

  return (
    <StyledTextField
      value={value}
      onChange={({ target: { value } }) => handleChange(value)}
      disabled={disabled || isLoading}
      $noSelect={noSelect}
      $isThin={isThin}
      $isLoading={isLoading}
      endAdornment={
        !noSelect &&
        currency && (
          <InputAdornment position="end">
            <CurrencyButton
              icon={currency}
              label={currency}
              isThin={isThin}
              onClick={onClick}
            />
          </InputAdornment>
        )
      }
      startAdornment={
        noSelect &&
        currency && (
          <InfoBlock $isThin={isThin}>
            <CurrencyInfo icon={currency} label={currency} />
          </InfoBlock>
        )
      }
      inputProps={{
        inputMode: "decimal",
        lang: "en-US",
        pattern: "[0-9]*([.][0-9]*?)?",
      }}
      fullWidth
      placeholder={placeholder ? placeholder : "0"}
    />
  );
};

interface CurrencyButtonProps extends ButtonProps {
  icon: string;
  label: string;
  isThin?: boolean;
}
const CurrencyButton: FC<CurrencyButtonProps> = ({
  icon,
  label,
  onClick,
  isThin,
}) => (
  <StyledButton onClick={onClick} $isThin={isThin}>
    {currencyIcons[icon.toUpperCase()] ? (
      currencyIcons[icon.toUpperCase()]
    ) : fiatIcons[icon.toUpperCase()] ? (
      fiatIcons[icon.toUpperCase()]
    ) : (
      <NAIcon />
    )}{" "}
    {label.toUpperCase()} <ArrowIcon />
  </StyledButton>
);

interface CurrencyInfoProps {
  icon: string;
  label: string;
}
const CurrencyInfo: FC<CurrencyInfoProps> = ({ icon, label }) => (
  <StyledInfo>
    {currencyIcons[icon] ? (
      currencyIcons[icon]
    ) : fiatIcons[icon] ? (
      fiatIcons[icon]
    ) : (
      <NAIcon />
    )}{" "}
    <AppText>{label}</AppText>
  </StyledInfo>
);

interface StyledButtonProps {
  $isThin?: boolean;
}
const StyledButton = styled(Button)<StyledButtonProps>`
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 12.4rem;
  max-width: 12.4rem;
  max-height: ${({ $isThin }) => ($isThin ? "4.4rem" : "5.4rem")};
  min-height: ${({ $isThin }) => ($isThin ? "4.4rem" : "5.4rem")};
  border-radius: 0;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  height: 100%;
  text-transform: none;
  font-weight: 500;
  font-size: 1.4rem;
  border-left: 1px solid ${colors.gray_100};
  background: linear-gradient(
    90deg,
    rgba(249, 250, 251, 1) 30%,
    rgba(247, 144, 9, 0.1) 100%
  );
  box-shadow: none;
  color: ${colors.gray_700};

  > svg:first-of-type {
    min-width: 3.4rem;
    max-width: 3.4rem;
    max-height: 3.4rem;
    min-height: 3.4rem;
    margin-right: 0.8rem;
  }
  > svg:last-of-type {
    min-width: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    min-height: 2rem;
    margin-left: 0.8rem;
    path {
      stroke: ${colors.primary_600};
    }
  }

  &:disabled {
    background-color: ${colors.gray_25};
    border: 1px solid ${colors.gray_50};
    color: ${colors.gray_300};
  }
`;

const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-transform: none;
  font-weight: 500;
  font-size: 1.2rem;
  box-shadow: none;
  color: ${colors.gray_700};

  & > p.MuiTypography-root {
    font-size: 1.1rem;
  }

  & > svg {
    max-width: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    margin-right: 0.4rem;
  }

  @media (min-width: 36em) {
    & > p.MuiTypography-root {
      font-size: 1.4rem;
    }

    & > svg {
      max-width: 3.6rem;
      min-width: 3.6rem;
      min-height: 3.6rem;
      max-height: 3.6rem;
      margin-right: 0.8rem;
    }
  }
`;

interface StyledTextFieldProps {
  $noSelect?: boolean;
  $isThin?: boolean;
  $isLoading?: boolean;
}

const StyledTextField = styled(OutlinedInput)<StyledTextFieldProps>`
  ${({ $noSelect }) => $noSelect && `padding-left: 0px;`}

  &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline,
  &:focus-within .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${colors.primary_500};
  }
  &.Mui-focused ${StyledButton}, &:not(.Mui-disabled):hover ${StyledButton} {
    border-left: 1px solid ${colors.primary_500};
  }
  .MuiOutlinedInput-notchedOutline {
    transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .MuiInputBase-input {
    padding: ${({ $isThin }) =>
      $isThin
        ? "1.05rem 0.6rem 1.05rem 1.2rem"
        : "1.6rem 0.6rem 1.6rem 1.2rem"};
    font-size: 1.6rem;
    font-weight: 500;
    color: ${colors.gray_700};
    border-radius: 0.8rem;
    background-color: ${colors.white};
    transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    ${({ $isLoading }) => $isLoading && `filter: blur(8px);`}

    &:focus {
      color: ${colors.primary_600};
    }
    &:disabled {
      color: ${colors.gray_500};
      background-color: ${colors.gray_50};
    }
    &:disabled + fieldset {
      border: 1px solid ${colors.gray_100};
    }
  }
  &.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${colors.gray_100};
  }
  .MuiInputBase-root {
    border-radius: 0.8rem;
    ${({ disabled }) => disabled && `background-color: ${colors.gray_50};`}
  }
  fieldset {
    border: 1px solid ${colors.gray_100};
    border-radius: 0.8rem;
  }
  .MuiInputAdornment-root {
    height: 100%;
    margin-left: 0;
    max-height: ${({ $isThin }) => ($isThin ? "4.4rem" : "5.6rem")};
    min-height: ${({ $isThin }) => ($isThin ? "4.4rem" : "5.6rem")};
  }
`;

export default CurrencyInput;
